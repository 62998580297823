import { navigateTo } from "gatsby-link"
import React from "react"
import Recaptcha from "react-google-recaptcha"
import "../assets/styles/index.scss"
import BrandVoice from "../components/brand-voice"
import Footer from "../components/footer"
import Head from "../components/global/head"
import TopNavigation from "../components/global/top_navigation"
import MainLayout from "../layouts/main_layout"
import styles from "./form.module.scss"

declare const SITE_RECAPTCHA_KEY: string

interface Data {
  [key: string]: string
}

function encode(data: Data) {
  return Object.keys(data)
    .map((key) => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default class Contact extends React.Component {
  constructor(props: {}) {
    super(props)
    this.state = {
      "enquiry-type": "Build my brand",
    }
  }

  handleChange = (e: React.SyntheticEvent) => {
    const element = e.target as HTMLInputElement
    this.setState({ [element.name as string]: element.value as string })
  }

  handleRecaptcha = (value: string) => {
    this.setState({ "g-recaptcha-response": value })
  }

  handleSubmit = (e: React.SyntheticEvent) => {
    e.preventDefault()
    const form = e.target as HTMLFormElement
    fetch("/", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name") as string,
        ...this.state,
      }),
    })
      .then(() => navigateTo(form.getAttribute("action") as string))
      .catch((error) => alert(error))
  }

  render() {
    return (
      <MainLayout>
        <Head
          description="Hardhat is an independent creative agency helping brands capitalise on the why, when, where, what and how of human behaviour."
          title="Let's chat"
          url="https://hardhat.com.au/contact"
          image="https://hardhat.com.au/images/og.jpg"
        />
        <TopNavigation
          theme="dark"
          isHome={false}
          hideChat={true}
          isContactUs={true}
        />
        <BrandVoice
          title="Built on behaviour"
          introText="What makes us stand in line for a spot at a full restaurant? Decide to treat ourselves to a Friday Chilli Margarita or four? Maintain a drawer full of old iPhone boxes that we haven’t needed in years?"
          timelineItems={[
            {
              text:
                "Our brains operate somewhere between reason and random, and the field of behavioural economics can help us understand why.",
            },
            {
              text:
                "Our 2023 ‘Built on Behaviour’ report highlights the 12 heuristics (mental quirks or shortcuts) that are most relevant for the months ahead, as well as thought starters for putting them into action. ",
            },
          ]}
        />
        <div className="wrapper">
          <div className={`container ${styles.content}`}>
            <form
              className={styles.form}
              name="BoBDownload"
              method="post"
              action="/download-thanks/"
              data-netlify="true"
              data-netlify-recaptcha="true"
              onSubmit={this.handleSubmit}
              netlify-honeypot="bot-field"
            >
              <legend className={`display1 ${styles.legend}`}>
                Gimme
                <br />
                <span className="script text-yellow">Gimme</span>
              </legend>
              <p className={`display4 ${styles.intro}`}>
                Simply pop your details into the handy form below and we’ll send
                you a link for an instant download. In case you’re wondering,
                all of your competitors have already done it. Just saying.
              </p>
              <input type="hidden" name="form-name" value="Enquiry" />
              <div className={styles.hiddenField}>
                <label>
                  Don't fill this out if you're human:{" "}
                  <input name="bot-field" />
                </label>
              </div>
              <div className={styles.field}>
                <label className={`label1 ${styles.label}`} htmlFor="name">
                  My name is
                </label>
                <input
                  className={styles.input}
                  id="name"
                  type="text"
                  name="name"
                  required={true}
                  onChange={this.handleChange}
                />
              </div>

              <div className={styles.field}>
                <label className={`label1 ${styles.label}`} htmlFor="email">
                  You can email me at
                </label>
                <input
                  className={styles.input}
                  id="email"
                  type="email"
                  name="email"
                  required={true}
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.field}>
                <label className={`label1 ${styles.label}`} htmlFor="work">
                  I work at
                </label>
                <input
                  className={styles.input}
                  id="work"
                  type="text"
                  name="work"
                  required={true}
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.field}>
                <label
                  className={`label1 ${styles.label}`}
                  htmlFor="heard-from"
                >
                  I heard about you through
                </label>
                <input
                  className={styles.input}
                  id="heard-from"
                  type="text"
                  name="heard-from"
                  required={true}
                  onChange={this.handleChange}
                />
              </div>
              <div className={styles.field}>
                <Recaptcha
                  sitekey={SITE_RECAPTCHA_KEY}
                  onChange={this.handleRecaptcha}
                  theme="dark"
                />
              </div>
              <button
                type="submit"
                className={`${styles.button} label1 hover-target`}
                data-hover-background="rgba(253,185,19,0.4)"
              >
                Please send me the good stuff
              </button>
            </form>
          </div>
        </div>
        <Footer />
      </MainLayout>
    )
  }
}
